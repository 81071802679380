<template>
    <div :class="divClasses">
        <EnhancedImage
            v-if="imageUrl"
            :height="imageSize"
            :width="imageSize"
            :css-class="imageClasses"
            :src="imageUrl"
            :alt="imageAlt"
            multi-resolution
            lazy-load
            :self-hosted="imageSelfHosted" />
        <i v-else :class="iconClasses"></i>
    </div>
</template>
<script lang="ts">
type PartWithImage = {
    ImageUrl?: string;
    ImageURL?: string; // inconsistent capitalization in JSON
    PartNumber?: string;
    IsSelfHostedImage: boolean;
};
type Sizes = "sm" | "md" | "lg";

const IMAGE_PX: Record<Sizes, number> = { sm: 48, md: 64, lg: 96 };
const IMAGE_CLASSES: Record<Sizes, string> = {
    sm: "max-w-12 max-h-12",
    md: "max-w-16 max-h-16",
    lg: "max-w-24 max-h-24",
};
const DIV_CLASSES: Record<Sizes, string> = { sm: "size-12", md: "size-16", lg: "size-24" };
const ICON_CLASSES: Record<Sizes, string> = { sm: "text-3xl mt-2", md: "text-5xl mt-2", lg: "text-6xl mt-4" };
</script>
<script setup lang="ts">
const props = defineProps<{
    part?: PartWithImage | null;
    size: Sizes;
    alt?: string;
    imageUrl?: string;
    selfHosted?: boolean;
    partNumber?: string;
    cssClass?: string;
}>();

const imageUrl = computed(() => props.imageUrl ?? props.part?.ImageUrl ?? props.part?.ImageURL);

const imageAlt = computed(() => props.alt ?? props.partNumber ?? props.part?.PartNumber);

const imageSelfHosted = computed(() => props.selfHosted || props.part?.IsSelfHostedImage || false);

const imageSize = computed(() => IMAGE_PX[props.size].toString());

const imageClasses = computed(() => cn(IMAGE_CLASSES[props.size], "object-center w-full", props.cssClass));

const divClasses = computed(() => [DIV_CLASSES[props.size], "flex items-center justify-center text-center"].join(" "));

const iconClasses = computed(() => [ICON_CLASSES[props.size], "fass fa-image text-gray-300"].join(" "));
</script>
